import React from 'react';
import { Link } from "gatsby"
import Badge from "components/Badge/Badge.js";

export default function TagLinks(props) {

  const { color, badge, badgeColor, ...rest } = props;

  var tagsJSX = [""];
  for (const tag of props.tags) {

    if (badge === "true") {
      tagsJSX.push(
         <Link to={"#" + tag.slug} style={{ marginLeft: "10px", marginRight: "10px", color: color }} key={tag.slug}>
          <Badge color={badgeColor}>
            { tag.tagName + " " }
          </Badge>
         </Link>
      );

    } else {
      tagsJSX.push(
         <Link to={"#" + tag.slug} style={{ marginLeft: "10px", marginRight: "10px", color: color }} key={tag.slug}>
           { tag.tagName + " " }
         </Link>
      );
    }
  }
  return tagsJSX

}

// TODO - Fix so that it uses the default props
TagLinks.defaultProps = {
  color: "white",
  badge: false,
  badgeColor: "primary"
};
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Accordion from "components/Accordion/Accordion.js";

import Typography from '@material-ui/core/Typography';

import teamsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/teamsStyle.js";
import teamStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/teamStyle.js";

const style = {
  ...teamsStyle,
  ...teamStyle,
  justifyContentCenter: {
    justifyContent: "center"
  }
};

const useStyles = makeStyles(style);

export default function SectionFAQ() {
  const classes = useStyles();

  return (
    <div className={classes.section}>
      <Typography variant="h3" component="h3" color="textPrimary" className={classes.title}>Frequently Asked Questions</Typography>
      <div>
        <GridContainer>
          <GridItem xs={2} sm={2} md={2}>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
              <Accordion
                className={classes.card3}
                activeColor="rose"
                active={[0,1,2,3,4,5,6,7]}
                collapses={[
                  {
                    title: "Why join our Scentsy team?",
                    content: (
                    <div align="left">
                      <p>The word “why” is one of the first words we learn as a small child. We ask it every day. Why is the sky blue? Why do birds fly? Why do I have to eat vegetables?</p>
                      <p>Why would I start my own network marketing business? Isn’t that where people have those parties? Why would I want to put myself out there like that, be embarrassed, or hear no over and over again?</p>
                      <p>Network marketing is an opportunity to spend a relatively small amount of money to start a business. The product testing and research has already been done. The sourcing and manufacturing is taken care of. Shipping contracts negotiated. There is already established brand recognition. When you join, you get a business in a box. One that you can take and build into whatever you want it to be. I’m not talking about cardboard houses here, I’m talking about what you can turn your business into. You can be a hobbyist consultant, and just place enough orders to stay active, and get some free and half priced product. You can be a team builder, share the product and opportunity with others, help them to do the same.</p>
                      <p>Network marketing has evolved so much in recent years - you don’t HAVE to do home parties. You don’t HAVE to do Facebook parties. You don’t HAVE to make cold calls.</p>
                      <p>You get to choose HOW you show up, WHERE you show up, WHEN you work, and WHO you work with.</p>
                      <p>For me, network marketing - specifically partnering with Scentsy - has led to time freedom, which is priceless.</p>
                    </div>
                    )
                  },
                  {
                    title: "What does the Scentsy business opportunity mean to me?",
                    content: (
                      <div align="left">
                        <p>Besides earning monthly commissions based on sales, and bonuses for being a team leader, you get the opportunity to earn all-expense paid trips every year! We have been on eleven earned trips with Scentsy, to Hawaii, the Bahamas, Las Vegas, Cozumel, Disney World, Kansas City, a Mediterranean Cruise, a week at the Fairmont in Austin, Texas… and we also earned a spot in the Top 150 trip in 2020 (cancelled), and Disney World in 2021 – there is a new destination every year! I would love to help YOU start travelling for free!</p>
                      </div>
                    )
                  },
                  {
                    title: "What’s in the Scentsy business opportunity for you?",
                    content: (
                    <div align="left">
                      <p>When I joined Scentsy I had zero expectations. I loved the product, and wanted something fun to do. Before long, people wanted to join me! I continued to share the product casually with everyone. After a year and a half, I realized what I held in my hands. I learned the power of this business and what it could mean for my family, and I started trying. In fact, I started sharing the opportunity more and more, helping others to start their own businesses. While home on maternity leave, I earned my first free trip! I was able to quit my part-time bank job that year.</p>
                      <p>Fast forward a few more years, and I have earned ELEVEN free trips, had the privilege to speak on the company-wide training calls, to speak at our annual leadership retreat, to train at regional conventions, and to train at our national conventions!</p>
                      <p>You can learn from my mistakes, and my experience can shorten your learning curve. Whatever it is you want from your Scentsy business, I can help you to achieve the results you’re looking for.</p>
                    </div>
                    )
                  },
                  {
                    title: "How would I describe my Scentsy experience?",
                    content: (
                    <div align="left">
                      <p>I have probably felt the way you feel, at some point along my journey. While I have experienced back orders, sellouts, new product launches, and discontinuations, I have complete trust in the company. I have built relationships with the home office staff, and had the privilege of visiting Scentsy Commons (a.k.a. home office) in Meridian (Boise), Idaho.</p>
                      <p>I want to help as many people as I possibly can, to get to where I am right now. While I love sharing Scentsy and I love selling Scentsy, the real product here is the business opportunity. The potential is limitless &#8211; let&#8217;s chat and see if Scentsy is the right company for you!</p>
                    </div>
                    )
                  },
                  {
                    title: "What is our Scentsy team culture like?",
                    content: (
                    <div align="left">
                      <p>Our team culture is positive and encouraging – can you say that about your job? We cheer each other on, we don’t allow ourselves to fall victim to our own excuses, rather we un-become everything we’re not, and become more of what we are. Our motto is “You can sit with us” - we are the opposite of mean girl culture!</p>
                      <p>Scentsy offers a generous compensation plan, awards, recognition and the best incentive trips in the industry. I want to help more people experience those things! I would love to chat with you about how Scentsy can fit into your life! Please connect with me.</p>
                      <p>Now recruiting in the United States, Canada, and Mexico. Join my team!</p>
                    </div>
                    )
                  },
                  {
                    title: "Is it available in my country?",
                    content: (
                      <div align="left">
                        <p>Now recruiting in the United States, Canada, and Mexico. Join my team!</p>
                        <h5>Recruiting Scentsy Consultants in Canada</h5>
                        <p>Now recruiting in Ontario, Quebec, British Columbia, Alberta, Saskatchewan, Manitoba, Nova Scotia, New Brunswick, Prince Edward Island, Newfoundland, Nunavut, The Yukon, and The Northwest Territories.</p>
                        <h5>Recruiting Scentsy Consultants in the United States</h5>
                        <p>Now recruiting Independent Scentsy Consultants in California, Texas, Florida, New York, Illinois, Pennsylvania, Ohio, Georgia, North Carolina, Michigan, New Jersey, Virginia, Washington, Arizona, Massachusetts, Indiana, Tennessee, Missouri, Maryland, Wisconsin, Minnesota, Colorado, South Carolina, Alabama, Louisiana, Kentucky, Oregon, Oklahoma, Connecticut, Iowa, Utah, Mississippi, Arkansas, Kansas, Nevada, New Mexico, Nebraska, West Virginia, Idaho, Hawaii, New Hampshire, Maine, Rhode Island, Montana, Delaware, South Dakota, North Dakota, Alaska, Vermont, and Wyoming.</p>
                        <h5>Recruiting Scentsy Consultants in Mexico</h5>
                        <p>Now recruiting in Mexico, Veracruz, Jalisco, Puebla, Guanajuato, Chiapas, Nuevo León, Michoacán, Oaxaca, Chihuahua, Guerrero, Tamaulipas, Baja California, Sinaloa, Coahuila, Hidalgo, Sonora, San Luis Potosí­, Tabasco, Yucatán, Querétaro, Morelos, Durango, Zacatecas, Quintana Roo, Aguascalientes, Tlaxcala, Nayarit, Campeche, Colima, and Baja California Sur.</p>
                      </div>
                    )
                  }
                ]}
              />
          </GridItem>
          <GridItem xs={2} sm={2} md={2}>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}

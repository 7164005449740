import React from 'react';
import { Link, graphql } from 'gatsby';
import { GatsbySeo, LogoJsonLd, LocalBusinessJsonLd, SocialProfileJsonLd, BreadcrumbJsonLd, SitelinksSearchBoxJsonLd } from 'gatsby-plugin-next-seo';
import { GetSiteMetadata } from 'hooks/GetSiteMetadata';
import { getSrc } from "gatsby-plugin-image"

import useIsClient from 'hooks/useIsClient';

// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import ShoppingBasket from "@material-ui/icons/ShoppingBasket";

// core components
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderTemplate from "components/Header/HeaderTemplate.js";
import HeaderScentsy from "components/Header/HeaderScentsy.js";
import FooterTemplate from "components/Footer/FooterTemplate.js";

// Sections for this page
import SectionProduct from "views/HomePage/Sections/SectionProduct.js";
import SectionFragranceSystem from "views/HomePage/Sections/SectionFragranceSystem.js"
import SectionTeam from "views/HomePage/Sections/SectionTeam.js";
import SectionFAQ from "views/HomePage/Sections/SectionFAQ.js";
import SectionPills from "views/HomePage/Sections/SectionPills.js";

import landingPageStyle from "assets/jss/material-kit-pro-react/views/landingPageStyle.js";

export const query = graphql`
  query homePageBannerImage {
    bannerImage: imageSharp(resize: {originalName: {eq: "scentsy-wax-bars-on-tablecloth.jpg"}}) {
      gatsbyImageData(quality: 50)
    }
    bannerImageMobile: imageSharp(resize: {originalName: {eq: "scentsy-wax-bars-on-tablecloth.jpg"}}) {
      gatsbyImageData(width: 1200)
    }
  }
`

const useStyles = makeStyles(landingPageStyle);

export default function HomePage(props) {

  const { isClient } = useIsClient();
  if (isClient) {
    var isMobile = window.matchMedia('(max-width: 600px)').matches;
    var bannerImage;
    if (isMobile) {
      bannerImage = getSrc(props.data.bannerImageMobile)
    } else {
      bannerImage = getSrc(props.data.bannerImage)
    }
  }

  const classes = useStyles();

  // for SEO path
  const { siteMetadata } = GetSiteMetadata();
  var path = props.location.pathname;
  if (path.includes("/usa")) {
    path = path.replace("/usa", "");
  }

  return (
    <div>
      <GatsbySeo
        title={'SCENTSY online store' + props.pageContext.locale.appendToTitle}
        description='Shop wickless warmers + wax, oil diffusers, and everything home fragrance. Customer experience is our priority. Independent Scentsy Consultant since 2011'
        canonical={siteMetadata.siteUrl + props.pageContext.locale.path + path}
        language={props.pageContext.locale.isoLanguageCode}
        languageAlternates={[
          {
            hrefLang: props.pageContext.locale.isoLanguageCode,
            href: siteMetadata.siteUrl + props.pageContext.locale.path + path,
          },
          {
            hrefLang: props.pageContext.altLocale.isoLanguageCode,
            href: siteMetadata.siteUrl + props.pageContext.altLocale.path + path,
        }
        ]}
        openGraph={{
          type: 'website',
          title: 'SCENTSY online store' + props.pageContext.locale.appendToTitle,
          description: 'Looking for a reliable Scentsy fragrance consultant? Look no further. Shop online 24x7 now. Proudly serving the community since 2011, we are an Independent Scentsy Consultant committed to outstanding customer service and support.',
          url: siteMetadata.siteUrl + props.pageContext.locale.path + path,
          site_name: 'SCENTSY Online Store | Home Fragrance Biz',
          locale: props.pageContext.locale.ogLanguageCode,
          images: [
            {
              url: 'https://homefragrancebiz.com/files/images/seo/HomeFragranceBiz-Scentsy-Mini_Warmers_and_Wax_Bars.jpeg',
              width: 3009,
              height: 3009,
              alt: 'Picture of two Scentsy Mini Warmers next to stack of four fragrant Scentsy wax bars.',
            },
            {
              url: 'https://homefragrancebiz.com/files/images/seo/HomeFragranceBiz-Scentsy-Himalayan_Salt_Wax_Warmer.jpeg',
              width: 2767,
              height: 2075,
              alt: 'Picture of Scentsy Himalayan Salt wax warmer next to plant on shelf.',
            },
            {
              url: 'https://homefragrancebiz.com/files/images/seo/HomeFragranceBiz-Scentsy-Catalog_and_Testers.jpeg',
              width: 3024,
              height: 3024,
              alt: 'Picture of Scentsy catalog and wax testers.',
            },
            {
              url: 'https://homefragrancebiz.com/files/images/seo/HomeFragranceBiz-Scentsy-Fragrance_Pods.jpeg',
              width: 2813,
              height: 2813,
              alt: 'Colourful picture of Scentsy fragrance pods surrounding a Wall Fan Diffuser.',
            },
            {
              url: 'https://homefragrancebiz.com/files/images/seo/HomeFragranceBiz-Scentsy-Hand_Soap-Hand_Cream.jpeg',
              width: 3022,
              height: 3022,
              alt: 'Picture of Scentsy Hand Soap and Hand Cream next to stack of towels.',
            },
            {
              url: 'https://homefragrancebiz.com/files/images/seo/HomeFragranceBiz-Scentsy-Diffuser_Oils.jpeg',
              width: 3024,
              height: 3024,
              alt: 'Picture of Scentsy diffuser oils next to plant on tray.',
            },
            {
              url: 'https://homefragrancebiz.com/files/images/seo/HomeFragranceBiz-Scentsy-Wax_Bars_stacked.jpeg',
              width: 2835,
              height: 2835,
              alt: 'Picture of five Scentsy Bars - fragrant wax bars - stacked upside down on countertop.',
            },
            {
              url: 'https://homefragrancebiz.com/files/images/seo/HomeFragranceBiz-Scentsy-Wax_Bars_upside_down.jpeg',
              width: 2691,
              height: 1514,
              alt: 'Picture of Scentsy wax bars stacked and showing the back of the bars.',
            },
          ],
        }}
      />
      <SitelinksSearchBoxJsonLd
        url='https://homefragrancebiz.com/'
        searchHandlerURI='https://homefragrancebiz.com/search/?query='
      />
      <BreadcrumbJsonLd
        itemListElements={[
          {
            position: 1,
            name: 'Home',
          },
        ]}
      />
      <LogoJsonLd
        logo='https://homefragrancebiz.com/files/images/Home_Fragrance_Biz-Independent_Scentsy_Consultant-Logo-square.png'
        url='https://homefragrancebiz.com/'
      />
      <LocalBusinessJsonLd
        type='Store'
        id='https://homefragrancebiz.com/'
        name="Home Fragrance Biz, Independent Scentsy Consultant"
        description="Looking for a reliable Scentsy fragrance consultant? Look no further. Shop online 24x7 now. Proudly serving the community since 2011, we are an Independent Scentsy Consultant committed to outstanding customer service and support."
        url='https://homefragrancebiz.com/'
        telephone='+18449443348'
        address={{
          streetAddress: '15-75 Bayly Street West (Suite 170)',
          addressLocality: 'Ajax',
          addressRegion: 'ON',
          postalCode: 'L1S 7L7',
          addressCountry: 'CA',
        }}
        geo={{
          latitude: '43.852304',
          longitude: '-79.021920',
        }}
        images={[
          'https://homefragrancebiz.com/files/images/seo/HomeFragranceBiz-Scentsy-Mini_Warmers_and_Wax_Bars.jpeg',
          'https://homefragrancebiz.com/files/images/seo/HomeFragranceBiz-Scentsy-Himalayan_Salt_Wax_Warmer.jpeg',
          'https://homefragrancebiz.com/files/images/seo/HomeFragranceBiz-Scentsy-Catalog_and_Testers.jpeg',
          'https://homefragrancebiz.com/files/images/seo/HomeFragranceBiz-Scentsy-Fragrance_Pods.jpeg',
          'https://homefragrancebiz.com/files/images/seo/HomeFragranceBiz-Scentsy-Hand_Soap-Hand_Cream.jpeg',
          'https://homefragrancebiz.com/files/images/seo/HomeFragranceBiz-Scentsy-Diffuser_Oils.jpeg',
          'https://homefragrancebiz.com/files/images/seo/HomeFragranceBiz-Scentsy-Wax_Bars_stacked.jpeg',
          'https://homefragrancebiz.com/files/images/seo/HomeFragranceBiz-Scentsy-Wax_Bars_upside_down.jpeg'
        ]}
      />
      <SocialProfileJsonLd
        type='Organization'
        name='Home Fragrance Biz, Independent Scentsy Consultant'
        url='https://homefragrancebiz.com'
        sameAs={[
          'https://www.facebook.com/homefragrancebiz',
          'https://www.instagram.com/homefragrancebiz',
          'https://www.pinterest.com/homefragrancebiz',
          'https://twitter.com/homefragrancebz'
        ]}
      />

      <HeaderTemplate 
        locale={props.pageContext.locale}
        location={props.location}
      />
      <Parallax
        filter={"dark"}
        image={bannerImage}
        className={classes.parallax}
      >
        <div className={classes.container}>

          <HeaderScentsy />

          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>

              <Typography variant="body2" component="h1"><font size="1" color="#cccccc">SCENTSY online store | Home Fragrance Biz | {props.pageContext.locale.country}</font></Typography>
              <Typography variant="h6" component="p">What can we help you with?</Typography>
              <Typography variant="h2" component="h2">
                 <font size="8">Explore fragrance and home decor</font>
              </Typography>
              <Typography variant="body2" component="p">
              Scent is one of the most powerful memory triggers.
              We can help you select and customize the perfect fragrance experience
              for your home, office, home office, car, or vacation.
              </Typography>
              <br />
              <Link to={props.pageContext.locale.path + "/shop/"}>
                <Button
                  color="primary"
                  size="lg"
                
                >
                  <ShoppingBasket/>
                  BROWSE CATALOG
                </Button>
              </Link>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <SectionProduct />
          <SectionFragranceSystem />
          <SectionPills
            pageContext={props.pageContext}
          />
          <SectionTeam />
          <SectionFAQ />
        </div>
      </div>

      <FooterTemplate
        locale={props.pageContext.locale}
      />

    </div>
  );
}

import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
// @material-ui/icons
import FormatAlignLeft from "@material-ui/icons/FormatAlignLeft";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";

import { useStaticQuery, graphql, Link } from "gatsby"
import { getSrc } from "gatsby-plugin-image"
import TagLinks from "components/Tags/TagLinks.js";

import sectionPillsStyle from "assets/jss/views/sectionPillsStyleCustom.js";

const useStyles = makeStyles(sectionPillsStyle);

export default function SectionPills(props) {
  const classes = useStyles();
  const data = useStaticQuery(
      graphql`
        query BlogPostsHomePageQuery {
          allContentfulBlogPost(limit: 2, sort: {order: DESC, fields: publishedDate}) {
            edges {
              node {
                title
                slug
                featuredImage {
                  gatsbyImageData
                }
                excerpt {
                  childMarkdownRemark {
                    excerpt(pruneLength: 150)
                  }
                }
                tags {
                  tagName
                  slug
                }
                publishedDate
              }
            }
          }
        }
      `
    )
  const blogPostsSeoMeta = [];

  data.allContentfulBlogPost.edges.map(edge => {
    const blogPostMeta = {
      headline: edge.node.title,
      image: getSrc(edge.node.featuredImage)
    }
    blogPostsSeoMeta.push(blogPostMeta);
  })

  return (
    <div className={classes.section}>
      <Typography variant="h3" component="h3" color="textPrimary" className={classes.title}>Recent posts</Typography>
      <GridContainer>
        { /** TODO - add function to ramdomize width of Grid Item xs={12} sm={6} md={6} vs. xs={12} sm={6} md={6} **/ }
        {data.allContentfulBlogPost.edges.map(edge => {
          const postUrl = props.pageContext.locale.path + '/blog/' + edge.node.slug + '/';
          return (
            <GridItem xs={12} sm={6} md={6} key={edge.node.title}>
              <Card
               raised
               background
               style={{ backgroundImage: "url(" + getSrc(edge.node.featuredImage) + ")" }}
              >
                <CardBody background>
                  <h6 className={classes.category}>
                    <TagLinks tags={edge.node.tags} badge="false"/>
                  </h6>
                  <Link to={postUrl}>
                    <h3 className={classes.cardTitle}>
                      {edge.node.title}
                    </h3>
                  </Link>
                  <p className={classes.category}>
                    {edge.node.excerpt.childMarkdownRemark.excerpt}
                  </p>
                  <Link to={postUrl}>
                    <Button round color="primary">
                      <FormatAlignLeft className={classes.icons} /> Read post
                    </Button>
                  </Link>
                 </CardBody>
               </Card>
            </GridItem>
          )
        })}
      </GridContainer>
    </div>
  );
}

import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";


// @material-ui/icons
import HomeWork from "@material-ui/icons/HomeWork";
import SentimentSatisfied from "@material-ui/icons/SentimentSatisfied";
import LocalFlorist from "@material-ui/icons/LocalFlorist"
import Typography from '@material-ui/core/Typography';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import productStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(productStyle);

export default function SectionProduct() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={12} md={8}>
          <Typography variant="h3" component="h3" color="textPrimary" className={classes.title}>What can we help you with?</Typography>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Customer Experience"
              description="Reliably serving customers since 2011, we treat our customers like family. We value their business, and go out of our way to save them money, feel special, and keep them up-to-date. Customer experience is our priority!"
              icon={SentimentSatisfied}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Fragrance Concierge"
              description="Scent is one of the most powerful memory triggers, let us help you choose what your memories will smell like. We can help you select and customize the perfect fragrance experience for your home, office, home office, car, or vacation."
              icon={LocalFlorist}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Business Coaching"
              description="Start your own business with Scentsy – whether you’re looking for a side hustle, a hobby, or to replace an income. Our team culture is positive and encouraging. We cheer each other on, and don’t allow ourselves to fall victim to our own excuses."
              icon={HomeWork}
              iconColor="warning"
              vertical
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}

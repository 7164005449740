import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Typography from '@material-ui/core/Typography';


import teamsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/teamsStyle.js";
import teamStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/teamStyle.js";

// nodejs library that concatenates classes
import classNames from "classnames";

const style = {
  ...teamsStyle,
  ...teamStyle,
  justifyContentCenter: {
    justifyContent: "center"
  }
};

const useStyles = makeStyles(style);

export default function SectionFragranceSystem() {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const data = useStaticQuery(graphql`
        query SectionFragranceSystemBannerQuery {
          diffuserImage: imageSharp(resize: {originalName: {regex: "/r123homeenrichdiffuser357isoss19.png/"}}) {
              gatsbyImageData(placeholder: BLURRED)
          }
          warmerImage: imageSharp(resize: {originalName: {eq: "rahomecharredglownowaxisofw19.png"}}) {
              gatsbyImageData(placeholder: BLURRED)
          }
          flowerImage: imageSharp(resize: {originalName: {eq: "r1homefragranceflowerisofw190.png"}}) {
              gatsbyImageData(placeholder: BLURRED)
          }
        }

      `)

  return (
    <div className={classes.section}>
        <GridContainer justifyContent="center">
          <GridItem xs={12} sm={12} md={8}>
            <Typography variant="h3" component="h3" color="textPrimary" className={classes.title}>Wickless wax warmers and home fragrance systems</Typography>
            <Typography variant="body1" className={classes.description}>
            Scentsy is an industry-leading fragrance company that pioneered the popular, safe candle warmer concept.

            Whether you are looking to put the finishing touches on your home decor with a few carefully chosen accessories and fragrances, buy beautiful gifts for friends – Scentsy is the answer!
            </Typography>
          </GridItem>
        </GridContainer>
        <div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <Card plain>
                <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                  <Link to="/shop/diffusers-and-oils/">
                    <GatsbyImage image={data.diffuserImage.gatsbyImageData} alt="Picture of Scentsy Diffuser" className={imageClasses}/>
                  </Link>
                </GridItem>
                <h4 className={classes.cardTitle}>
                  Diffusers
                  <br />
                  <small className={classes.smallTitle}>Essential and Natural Oils</small>
                </h4>
                <CardBody>
                  <p className={classes.description}>
                  Enjoy immediate, all-natural scents along with adjustable colour, light and fragrance strength. Beautiful diffuser designs where art meets science.
                  </p>
                </CardBody>
                
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Card plain>
                <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                  <Link to="/shop/warmers-and-wax/">
                    <GatsbyImage image={data.warmerImage.gatsbyImageData} alt="Picture of Scentsy Wickless Warmer" className={imageClasses}/>
                  </Link>
                </GridItem>
                <h4 className={classes.cardTitle}>
                  Wickless Warmers
                  <br />
                  <small className={classes.smallTitle}>Fragrant Wax</small>
                </h4>
                <CardBody>
                  <p className={classes.description}>
                    Beautiful designs to suit every taste and décor. Warm our Scentsy Bars to fill your space with our exclusive scents, inspiring imagination and memories. 
                  </p>
                </CardBody>
                
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Card plain>
                <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                  <Link to="/shop/unplugged/">
                    <GatsbyImage image={data.flowerImage.gatsbyImageData} alt="Picture of Scentsy Fragrance Flower" className={imageClasses}/>
                  </Link>
                </GridItem>
                <h4 className={classes.cardTitle}>
                  Unplugged
                  <br />
                  <small className={classes.smallTitle}>Portable Fragrance Solutions</small>
                </h4>
                <CardBody>
                  <p className={classes.description}>
                  Take fragrance anywhere! Our on-the-go fragrance products are designed to deliver your favorite scents on every adventure great and small.
                  </p>
                </CardBody>
                
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
  );
}

import React from "react";
import { graphql, useStaticQuery } from "gatsby";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Accordion from "components/Accordion/Accordion.js";
import Button from "components/CustomButtons/Button.js";

import Typography from '@material-ui/core/Typography';


// @material-ui/icons
import Phone from "@material-ui/icons/Phone";

import Hidden from "@material-ui/core/Hidden";

import useIsClient from 'hooks/useIsClient';

import { GatsbyImage } from "gatsby-plugin-image";

import teamsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/teamsStyle.js";
import teamStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/teamStyle.js";

const style = {
  ...teamsStyle,
  ...teamStyle,
  justifyContentCenter: {
    justifyContent: "center"
  }
};

const useStyles = makeStyles(style);

export default function SectionTeam(props) {
  const classes = useStyles();
  const {sectionExpanded, sectionBaseHLevel} = props;

  let activeSections;

  if (sectionExpanded === "true") {
    activeSections = [0,1,2,3,4,5,6,7];
  } else {
    activeSections = undefined;
  }

  let hLevel

  if (sectionBaseHLevel === "h2") {
    hLevel = "h2"
  } else {
    hLevel = "h4"
  }

  const data = useStaticQuery(graphql`
    query teamImage {
      teamImage: imageSharp(resize: {originalName: {eq: "Kathryn-Warmer.jpg"}}) {
        gatsbyImageData(placeholder: BLURRED, quality: 50)
      }
      teamImageMobile: imageSharp(resize: {originalName: {eq: "Kathryn-Warmer.jpg"}}) {
        gatsbyImageData(placeholder: BLURRED, width: 1200)
      }
      simpleImage: imageSharp(resize: {originalName: {eq: "Simple.jpg"}}) {
        gatsbyImageData(placeholder: BLURRED, quality: 50)
      }
      simpleImageMobile: imageSharp(resize: {originalName: {eq: "Simple.jpg"}}) {
        gatsbyImageData(placeholder: BLURRED, width: 1200)
      }
      authenticImage: imageSharp(resize: {originalName: {eq: "Kathryn-Authentic.jpg"}}) {
        gatsbyImageData(placeholder: BLURRED, quality: 50)
      }
      authenticImageMobile: imageSharp(resize: {originalName: {eq: "Kathryn-Authentic.jpg"}}) {
        gatsbyImageData(placeholder: BLURRED, width: 1200)
      }
      generousImage: imageSharp(resize: {originalName: {eq: "Cause.jpg"}}) {
        gatsbyImageData(placeholder: BLURRED, quality: 50)
      }
      generousImageMobile: imageSharp(resize: {originalName: {eq: "Cause.jpg"}}) {
        gatsbyImageData(placeholder: BLURRED, width: 1200)
      }
      selfImage: imageSharp(resize: {originalName: {eq: "Kathryn-Norway.jpg"}}) {
        gatsbyImageData(placeholder: BLURRED, quality: 50)
      }
      selfImageMobile: imageSharp(resize: {originalName: {eq: "Kathryn-Norway.jpg"}}) {
        gatsbyImageData(placeholder: BLURRED, width: 1200)
      }

    }
  `)

  const { isClient } = useIsClient();
  if (isClient) {
    var isMobile = window.matchMedia('(max-width: 600px)').matches;
    var teamImage;
    if (isMobile) {
      teamImage = data.teamImageMobile
    } else {
      teamImage = data.teamImage
    }
  }


  return (
    <div className={classes.section} style={{textAlign: "center"}}>
      <Typography variant="h3" component="p" color="textPrimary" className={classes.title}>About me</Typography>
      <div>
        <GridContainer>
          <GridItem xs={2} sm={2} md={2}>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <Card profile plain className={classes.card3}>
              <GridContainer>
                <GridItem xs={12} sm={5} md={5}>
                  <CardHeader image plain>
                    <GatsbyImage image={data.teamImage.gatsbyImageData} alt="Picture of Kathryn Gibson"/>
                  </CardHeader>
                </GridItem>
                <GridItem xs={12} sm={7} md={7}>
                  <CardBody plain>
                    <p className={classes.cardTitle}>Kathryn Gibson</p>
                    <Typography variant="body2" color="textPrimary" className={classes.cardCategory}>SMALL BUSINESS OWNER / COACH</Typography>
                    <hr/>
                    <p className={classes.description}>
                      Hi! I’m Kathryn, and I help people join Scentsy in a way that looks and feels right for them.
                    </p>
                  </CardBody>
                </GridItem>
              </GridContainer>
            </Card>
          </GridItem>
          <GridItem xs={2} sm={2} md={2}>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={2} sm={2} md={2}>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <Accordion
              className={classes.card3}
              activeColor="rose"
              active={activeSections}
              hLevel={hLevel}
              collapses={[
                {
                  title: "You don’t need to have it all figured out to take the next step.",
                  content: (
                    <div align="left">
                      <p>I want to help you build a business, side-hustle, or hobby that works for you. Instead of changing your life, you can have something that fits into your world - whatever that looks like.</p>
                      <p>I love seeing people gain confidence, grow and make Scentsy something that they can call their own.</p>
                    </div>
                  )
                },
                {
                  title: "“But don’t I have to…",
                  content: (
                    <div align="left">
                      <ul>
                        <li>Be spammy or salesy?</li>
                        <li>Bug all my friends or do cold calls?</li>
                        <li>Figure out some complicated system?</li>
                      </ul>
                      <p>With Scentsy, you’re not figuring out systems, doing market research and product testing. You don’t need to be a social media expert or give big presentations or spend all your time DM-ing strangers.</p>
                      <p>You just need to show up as you are, with what you have and do what you can.</p>
                      <p>It’s honestly that easy and doable.</p>
                    </div>
                  )
                },
                {
                  title: "Be part of something that is simple, authentic and generous.",
                  content: (
                    <div align="left">
                      <GridContainer>
                        <GridItem xs={12} sm={9} md={6}>
                          <CardBody plain>
                          <Typography variant="h4" component="h5" color="textPrimary" className={classes.title}>Simple</Typography>
                          <p>Building a business does not have to be difficult or overly time-consuming.</p>
                          <p>Scentsy fits into your life as it is. By doing simple things consistently, you can be successful.</p>
                          </CardBody>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <CardHeader image plain>
                            <GatsbyImage image={data.simpleImage.gatsbyImageData} alt="Picture of Scentsy catalog, warmer, wax, and testers on table"/>
                          </CardHeader>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                          <Hidden smDown implementation="js">
                            <CardHeader image plain>
                              <GatsbyImage image={data.authenticImage.gatsbyImageData} alt="Picture of Kathryn holding a clean Scentsy air purifier filter next to a dirty one"/>
                            </CardHeader>
                          </Hidden>
                          <Hidden mdUp implementation="js">
                            <CardBody plain>
                              <Typography variant="h4" component="h5" color="textPrimary" className={classes.title}>Authentic</Typography>
                              <p>Not a salesperson? Me either! I’m more  likely to be standing in the corner than the life of the party.</p>
                              <p>Don’t want to do home parties? Then don’t! This business model works when you do one simple thing: be yourself. Trust me, your people will appreciate it.</p>
                            </CardBody>
                          </Hidden>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                          <Hidden mdUp implementation="js">
                            <CardHeader image plain>
                              <GatsbyImage image={data.authenticImage.gatsbyImageData} alt="Picture of Kathryn holding a clean Scentsy air purifier filter next to a dirty one"/>
                            </CardHeader>
                          </Hidden>
                          <Hidden smDown implementation="js">
                            <CardBody plain>
                              <Typography variant="h4" component="h5" color="textPrimary" className={classes.title}>Authentic</Typography>
                              <p>Not a salesperson? Me either! I’m more  likely to be standing in the corner than the life of the party.</p>
                              <p>Don’t want to do home parties? Then don’t! This business model works when you do one simple thing: be yourself. Trust me, your people will appreciate it.</p>
                            </CardBody>
                          </Hidden>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <CardBody plain>
                            <Typography variant="h4" component="h5" color="textPrimary" className={classes.title}>Generous</Typography>
                            <p>When we do things simply and authentically, generosity naturally flows from that.</p>
                            <p>You can be more generous with your time and spend it with the people you love. You can be generous to the causes you care about.</p>
                            <p>Our team values generosity, whether it’s with praise and encouragement or spending time helping each other out.</p>
                          </CardBody>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <CardHeader image plain>
                            <GatsbyImage image={data.generousImage.gatsbyImageData} alt="Picture of Kathryn Gibson at SickKids"/>
                          </CardHeader>
                        </GridItem>
                      </GridContainer>
                    </div>
                  )
                },
                {
                  title: "Do it your way, but not on your own.",
                  content: (
                    <div align="left">
                      <p>
                        Community is so important and, not to brag, but our team - Faith, Trust & Purple Pixie Dust - is as magical as it sounds. Our motto is “You can sit with us” - we are all about cheering each other on and calling each other out (with kindness) when we make excuses. We work to un-become what we aren’t and become more of what we are!
                      </p>
                      <p>
                        On our team, you have access to a wealth of knowledge and experience. Since joining Scentsy, I have
                      </p>
                      <ul>
                        <li>Earned 12 trips (so far) to Disney World, Norway, Hawaii and more</li>
                        <li>Spoke at company-wide training calls and our annual leadership retreat</li>
                        <li>Trained at regional conventions and our national conventions</li>
                      </ul>
                      <p>
                        When I joined Scentsy, I had zero expectations and was simply looking for something fun. Since then, I have been doing this through pregnancy, having a newborn, moving, potty training, moving again, living in a pandemic, wrangling my boys into doing virtual school, and more.
                      </p>
                      <p>
                        I have made some mistakes along the way (okay, a lot of mistakes!) and can tell you exactly how to avoid them.
                      </p>
                      <p>
                        We also have weekly training, office hours to hop on and ask questions and a Facebook group with over 500 consultants so you can connect with other people just like you.
                      </p>
                      <p>
                        One thing you need to know about me: I’m stubbornly consistent so I will not stop showing up for you.
                      </p>
                    </div>
                  )
                },
                {
                  title: "Be part of something that is simple, authentic and generous.",
                  content: (
                    <div align="left">
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <CardHeader image plain>
                            <GatsbyImage image={data.selfImage.gatsbyImageData} alt="Picture of Kathryn Gibson"/>
                          </CardHeader>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <CardBody plain>
                            <p>Here is a little bit more about me!</p>
                            <ul>
                              <li>I absolutely love to travel - in fact, that’s part of why I built my Scentsy business. I saw other people taking great trips and wanted to do that too!</li>
                              <li>My house is always busy and loud between my two sons (9 and 14) and our rambunctious dog, Buddy.</li>
                              <li>One thing I can never give up: coffee. (Trust me, you don’t want to talk to me without it!)</li>
                              <li>I am a total introvert - I’m much happier having smaller conversations than being in a big group.</li>
                            </ul>
                          </CardBody>
                        </GridItem>
                      </GridContainer>
                    </div>
                  )
                },
                {
                  title: "Let’s see if we are a good fit!",
                  content: (
                    <div align="left">
                      <p>If you’re curious about Scentsy, want to know more about our team or just want to grab a coffee, I am always up for that!  Click the link below and let’s get to know each other better.</p>

                      <center>
                        <Button
                          href="https://calendly.com/homefragrancebiz/30min"
                          color="primary"
                          target="_blank"
                          rel="nofollow noreferrer"
                          className={classes.navButton}
                          round
                        >
                          <Phone className={classes.icons} /> BOOK A CALL
                        </Button>
                      </center>

                    </div>
                  )
                },
              ]} />
          </GridItem>
          <GridItem xs={2} sm={2} md={2}>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
